<template>
  <div class="flex headerBarWrap">
    <!--<bread-crumbs :show="breadCrumbs" />-->

    <!--<v-btn
      v-if="isFiltersShow"
      class="filterBtn"
      @click="openFilterModal"
    >
      <Icons name="tune" />
      <span>Filter</span>
    </v-btn>-->
    <div
      ref="filterModal"
      class="filtersModal"
    >
      <div class="top-filters">
        <!-- Route filter -->
        <v-card v-if="filterNames.includes('route')">
          <v-select
            v-model="options.route_id"
            filled
            dense
            clearable
            :items="routeOptions"
            item-text="route_name"
            item-value="id"
            :label="$t('select_route')"
            @change="emitData"
          />
        </v-card>
        <!-- Route Filter -->
        <!-- Date 1 -->

        <template v-if="isDateShow">
          <DateFilter
            v-model="options.date"
            dense
            :default-selected="dateSelected"
            :validate-date-limit="dateValidation"
            :custom="dateCustomOptions"
            :date-option-type="options.dateOptionType"
            :date-type="options.dateType"
            :date-range="options.date"
            @change="onDateChange"
            @key="onDateKeyChange"
          />
        </template>
        <!-- Date 1 -->

        <!-- Single Date Select -->
        <v-card v-if="isSingleDateShow">
          <select-date
            v-model="options.date"
            :label="$t('select_date_*')"
          />
        </v-card>
        <!-- :rules="[rules.required]" -->
        <!-- :min="dateValidation('min')"
        :max="dateValidation('max')" -->
        <!-- Single Date Select -->

        <!-- Product Fleet and Tenent -->
        <template v-if="!noFilters">
          <v-card v-if="authUser.isAdmin">
            <v-select
              v-model="options.product"
              filled
              dense
              :disabled="productDisable"
              :items="productOptions"
              :loading="tenantLoading"
              item-value="id"
              item-text="value"
              :label="$t('product')"
              @change="onProductChange"
            />
          </v-card>
          <!-- || authUser.user_nature == 'fleet' -->
          <v-card v-if="options.product == 'oem' && authUser.isAdmin">
            <v-select
              v-model="options.tenant_id"
              filled
              dense
              :items="tenantOEM"
              :loading="tenantLoading"
              item-value="id"
              item-text="name"
              :clearable="!preSelectFilter"
              :label="tenantName"
              @change="onTenantChange"
            />
            <!-- :clearable="isClearable" -->
          </v-card>
          <v-card v-if="options.product == 'fleet' && authUser.isAdmin">
            <v-select
              v-model="selectedFleet"
              filled
              dense
              :items="tenantFLEET"
              :loading="tenantLoading"
              item-text="name"
              :clearable="!preSelectFilter"
              return-object
              :label="$t('select_fleet')"
              @change="onFleetChange"
            />
          </v-card>
        </template>
        <!-- Add Filter By Array -->

        <!-- Search -->
        <v-card
          v-if="filterNames.includes('search')"
          class="searchField"
        >
          <v-text-field
            v-model="options.search"
            filled
            dense
            clearable
            maxlength="20"
            :label="$t(searchLabel)"
            @input="onSearch"
            @click:clear="
              () => {
                options.search = null;
                emitData();
              }
            "
          />
        </v-card>
        <!-- Search -->
        <!-- Make type for Md Makes -->
        <v-card v-if="filterNames.includes('make_type')">
          <v-select
            v-model="options.make_type"
            filled
            dense
            clearable
            :items="makeTypeList"
            item-text="name"
            item-value="id"
            :label="$t('select_mdmake_type')"
            @change="emitData"
          />
        </v-card>
        <!-- Make type for Md Makes -->
        <!-- Make -->
        <v-card v-if="filterNames.includes('make')">
          <v-select
            v-model="options.md_make_id"
            filled
            clearable
            dense
            :items="enable ? mdMakesList : []"
            item-value="id"
            item-text="name"
            :label="$t('ev_make')"
            @change="onMakeChange"
          />
        </v-card>
        <!-- Make -->
        <!-- Model -->
        <!-- :items="options.md_make_id || options.tenant_id ? mdModelsList : []" -->
        <v-card v-if="filterNames.includes('model')">
          <v-select
            v-model="options.md_model_id"
            filled
            clearable
            dense
            :items="options.md_make_id ? mdModelsList : []"
            item-value="id"
            item-text="name"
            :label="$t('ev_model')"
            @change="onModelChange"
          />
        </v-card>

        <!-- Model -->
        <!-- Vehicle Type -->
        <v-card v-if="filterNames.includes('vehicle_type')">
          <v-select
            v-model="options.vehicle_type_id"
            filled
            clearable
            dense
            :items="options.tenant_id ? vehicleTypesList : []"
            item-value="id"
            item-text="name"
            :label="$t('vehicle_type')"
            @change="emitData"
          />
        </v-card>
        <!-- Vehicle Type -->
        <!-- Motor Type -->
        <v-card v-if="filterNames.includes('motor_type')">
          <v-select
            v-model="options.motor_type_id"
            filled
            clearable
            dense
            :items="options.tenant_id ? motorTypesList : []"
            item-value="id"
            item-text="name"
            :label="$t('motor_type')"
            @change="emitData"
          />
        </v-card>
        <!-- Motor Type -->
        <!-- Battery Slot Type -->
        <v-card v-if="filterNames.includes('battery_slot_type')">
          <v-select
            v-model="options.battery_slot_type"
            filled
            clearable
            dense
            :items="options.tenant_id ? ['fixed', 'swappable'] : []"
            item-value="id"
            item-text="name"
            :label="$t('battery_slot_type')"
            @change="emitData"
          />
        </v-card>
        <!-- Battery Slot Type -->

        <!-- is Assigned for Vehicles -->
        <v-card v-if="filterNames.includes('is-assigned_only')">
          <v-select
            v-model="options.is_assigned"
            filled
            dense
            clearable
            :items="vehicleType"
            item-text="name"
            item-value="id"
            :label="$t('vehicle_status')"
            @change="emitData"
          />
        </v-card>
        <!-- is Assigned for Vehicles -->
        <!-- Help and support  Category-->
        <v-card v-if="filterNames.includes('category')">
          <!-- value="asdas" -->
          <v-select
            v-model="options.category"
            filled
            dense
            clearable
            :items="ticketCategoryLists"
            :label="$t('category')"
            @change="emitData"
          />
        </v-card>
        <!-- Help and support  Category-->

        <!-- User Type  -->
        <v-card
          v-if="filterNames.includes('customer') && options.product == 'oem'"
        >
          <v-select
            v-model="options.user_type"
            filled
            dense
            clearable
            :items="userTypeOptions"
            item-value="id"
            item-text="name"
            :label="$t('customer')"
            @click:clear="() => (options.oem_b2b_id = null)"
            @change="onUserTypeChange"
          />
        </v-card>
        <v-card v-if="options.user_type == 'business'">
          <v-select
            v-model="options.oem_b2b_id"
            filled
            dense
            clearable
            :items="oemB2bList"
            item-value="id"
            item-text="business_name"
            :label="$t('business')"
            @change="onBusinessChange"
          />
        </v-card>
        <!-- User Type  -->

        <!-- Status -->
        <v-card v-if="filterNames.includes('status')">
          <v-select
            v-model="options.status"
            filled
            clearable
            dense
            :items="statusOptions"
            item-value="id"
            item-text="name"
            :label="$t('status')"
            @change="onStatusChange"
          />
        </v-card>
        <!-- Status -->
        <!--  -->
        <v-card v-if="filterNames.includes('calendar_type')">
          <v-select
            v-model="options.calendar_type"
            filled
            dense
            :items="['week', 'day']"
            item-value="id"
            item-text="name"
            :label="$t('calendar_type')"
            @change="emitData"
          />
        </v-card>
        <!--  -->
        <!-- Product Tier  -->

        <template v-if="filterNames.includes('product_tier')">
          <v-card>
            <v-select
              v-model="options.product_id"
              filled
              dense
              clearable
              :items="productWithTiers"
              item-value="id"
              item-text="name"
              :label="$t('product')"
              @change="onProductTierChange"
            />
          </v-card>
          <v-card v-if="options.product_id">
            <v-select
              v-model="options.product_tier_id"
              filled
              dense
              clearable
              :items="filterProductTier(options.product_id)"
              item-value="product_tier_id"
              item-text="name"
              :label="$t('product_tiers')"
              @change="emitData"
            />
          </v-card>
        </template>
        <!-- Product Tier  -->
        <!-- IOT Device Make -->
        <v-card v-if="filterNames.includes('iot_device_make')">
          <v-select
            v-model="options.iot_device_make_id"
            filled
            clearable
            dense
            :items="iotDeviceMakesList"
            item-value="id"
            item-text="name"
            :label="$t('manufacturer')"
            @change="onStatusChange"
          />
        </v-card>
        <!-- IOT Device Maker -->

        <!-- Vehicle List -->
        <v-card v-if="filterNames.includes('vehicle')">
          <div class="allVehicleSelect">
            <v-autocomplete
              v-model="vehiclesCheckbox"
              filled
              dense
              :loading="isVehicleList"
              :items="options.tenant_id || options.fleetId ? vehicleList : []"
              item-value="id"
              item-text="name"
              clearable
              multiple
              :label="$t('select_vehicles')"
              @change="onVehicleChangeCheckBox"
            >
              <template
                v-if="
                  options.tenant_id || options.fleetId
                    ? vehicleList.length > 0
                    : false
                "
                v-slot:prepend-item
              >
                <v-checkbox
                  v-model="selectAllVehicles"
                  class="selected allCheckBox"
                  label="All"
                  dense
                  @change="onToggleChange"
                />
              </template>
              <template v-slot:selection="data">
                <span
                  v-if="data.index === 0"
                  class="selected"
                >
                  <span>{{ data.item.name }}</span>
                </span>
                <span
                  v-if="data.index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ options.vehicle_ids.length - 1 }}... )
                </span>
              </template>
            </v-autocomplete>
          </div>
        </v-card>
        <v-card v-if="filterNames.includes('single_vehicle')">
          <v-autocomplete
            v-model="options.vehicle_id"
            filled
            dense
            :loading="isVehicleList"
            :items="options.tenant_id ? vehicleList : []"
            item-value="id"
            item-text="name"
            clearable
            :label="$t('select_vehicle')"
            @change="onVehicleChange"
          />
        </v-card>
        <!-- Vehicle List -->
        <!-- Streams -->
        <v-card
          v-if="filterNames.includes('streams')"
          class="allVehicleSelect"
        >
          <v-select
            v-model="options.flespi_stream_id"
            filled
            clearable
            dense
            :items="streamsList"
            item-value="flespi_stream_id"
            item-text="name"
            :label="$t('stream')"
            multiple
            @change="onStatusChange"
          >
            <template v-slot:selection="{ item, index }">
              <span
                v-if="index === 0"
                class="selected"
              >
                <span>
                  {{ item.name }}
                </span>
              </span>

              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                +{{ options.flespi_stream_id.length - 1 }}
              </span>
            </template>
          </v-select>
        </v-card>
        <!-- Streams -->
        <!-- Plugins -->
        <v-card
          v-if="filterNames.includes('plugins')"
          class="allVehicleSelect"
        >
          <v-select
            v-model="options.flespi_plugin_id"
            filled
            clearable
            dense
            :items="pluginsList"
            item-value="flespi_plugin_id"
            item-text="name"
            :label="$t('plugin')"
            multiple
            @change="onStatusChange"
          >
            <template v-slot:selection="{ item, index }">
              <span
                v-if="index === 0"
                class="selected"
              >
                <span>
                  {{ item.name }}
                </span>
              </span>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                +{{ options.flespi_plugin_id.length - 1 }}
              </span>
            </template>
          </v-select>
        </v-card>
        <!-- Plugins -->
        <!-- Calcultors -->
        <v-card
          v-if="filterNames.includes('calculators')"
          class="allVehicleSelect"
        >
          <v-autocomplete
            v-model="options.flespi_calc_id"
            filled
            dense
            :items="calcsList"
            item-value="flespi_calc_id"
            item-text="name"
            :label="$t('calculator')"
            clearable
            multiple
            @change="onStatusChange"
          >
            <template v-slot:selection="{ item, index }">
              <span
                v-if="index === 0"
                class="selected"
              >
                <span>
                  {{ item.name }}
                </span>
              </span>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                +{{ options.flespi_calc_id.length - 1 }}
              </span>
            </template>
          </v-autocomplete>
        </v-card>
        <!-- Calcultors -->

        <!-- is Assigned for IOT device -->
        <template v-if="filterNames.includes('is-assigned')">
          <v-card>
            <v-select
              v-model="options.is_assigned"
              filled
              clearable
              dense
              :items="isAssigned"
              item-value="id"
              item-text="name"
              :label="$t('is_assigned')"
              @change="onIsAssigned"
            />
          </v-card>
          <v-card
            v-if="options.is_assigned === 'assigned'"
            class="allVehicleSelect"
          >
            <v-autocomplete
              v-model="options.tenant_id"
              filled
              clearable
              dense
              :items="tenantsList.filter((r) => r.is_independent == '1')"
              item-text="name"
              item-value="id"
              :label="$t('select_tenant')"
              @change="onStatusChange"
            >
              <template v-slot:selection="{ item, index }">
                <span
                  v-if="index === 0"
                  class="selected"
                >
                  <span>{{ item.name }}</span>
                </span>
                <span 
                  v-if="index === 1" 
                  class="grey--text text-caption"
                >
                  (+{{ options.tenant_id.length - 1 }}...)
                </span>
              </template>
            </v-autocomplete>
          </v-card>
        </template>
        <!-- is Assigned for IOT device -->
        <!-- Product FLeet Tenanat -->

        <!--  -->
        <v-card v-if="filterNames.includes('country')">
          <v-autocomplete
            v-model="options.country_id"
            filled
            clearable
            dense
            :items="countriesOptions"
            item-text="name"
            item-value="id"
            :label="$t('select_country')"
            @change="onCountryChange"
          />
        </v-card>
        <v-card v-if="filterNames.includes('state')">
          <v-autocomplete
            v-model="options.state_id"
            filled
            clearable
            dense
            :items="options.country_id ? statesOptions : []"
            item-text="name"
            item-value="id"
            :label="$t('select_state')"
            @change="onStateChange"
          />
        </v-card>
        <v-card v-if="filterNames.includes('city')">
          <v-autocomplete
            v-model="options.city_id"
            filled
            clearable
            dense
            :items="options.state_id ? citiesOptions : []"
            item-text="name"
            item-value="id"
            :label="$t('select_city')"
            @change="emitData"
          />
        </v-card>
        <!--  -->

        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import router from "@/router";
import { debounce } from "lodash";

export default {
  components: {
    //BreadCrumbs: () => import("@/layouts/core/BreadCrumbs.vue"),
    selectDate: () => import("@/components/base/SingleDatePicker.vue"),
    DateFilter: () => import("@/components/base/DateDropDown.vue"),
    //SlideInModal: () => import("@/components/base/SlideInModal.vue"),
    //Icons: () => import("@/components/base/icons.vue"),
  },
  props: {
    className: {
      type: String,
      default: () => {
        return "mx-0 mt-2";
      },
    },
    // cols: {
    //   type: String,
    //   default: () => {
    //     return "2";
    //   },
    // },
    breadCrumbs: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    preSelectFilter: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    isDateShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    dateOptionType: {
      type: String,
      default: () => {
        return "date";
      },
    },
    isSingleDateShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    dateCustomOptions: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    dateSelected: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    dateValidation: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    noFilters: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isFiltersShow: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    filterNames: {
      type: Array,
      default() {
        return [];
      },
    },
    searchLabel: {
      type: String,
      default() {
        return "chassis_no";
      },
    },
    productOptions: {
      type: Array,
      default() {
        return [
          { id: "oem", value: "OEM" },
          { id: "fleet", value: "FLEET" },
        ];
      },
    },
    selectedProduct: {
      type: String,
      default() {
        return "oem";
      },
    },
    productDisable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    statusOptions: {
      type: Array,
      default() {
        return [
          { id: "active", name: "Active" },
          { id: "inactive", name: "Inactive" },
          { id: "offline", name: "offline" },
        ];
      },
    },
  },
  data() {
    return {
      userType: null,
      enable: false,
      callAfterRendered: false,
      selectedFleet: {},
      options: {},
      //

      makeTypeList: [
        {
          id: "default",
          name: "Default",
        },
        {
          id: "tenant",
          name: "Tenant",
        },
      ],
      userTypeOptions: [
        {
          id: "individual",
          name: "Individual",
        },
        {
          id: "business",
          name: "Business",
        },
        { id: "internal", name: "Internal" },
      ],
      isAssigned: [
        {
          id: "assigned",
          name: "Assigned",
        },
        {
          id: "un-assigned",
          name: "Un-Assigned",
        },
      ],
      vehicleType: [
        { name: "Assigned", id: "is_assigned" },
        { name: "Un-Assigned", id: "is_unassigned" },
      ],
      dataTypeValue: false,
      selectAllVehicles: false,
      vehiclesCheckbox: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",

      //
      mdMakesList: "mdMakes/activelistMdMakes",
      mdModelsList: "mdModels/activelistMdModels",
      vehicleTypesList: "vehicleTypes/vehicleTypesList",
      motorTypesList: "motorTypes/motorTypesList",
      //
      oemB2bList: "oemBusiness/getOEMB2BList",
      //
      // tenant mangemnet Prdouct tier
      productWithTiers: "products/getProductWithTiers",
      //
      isVehicleList: "vehicles/getIsLoadingVehicles",
      vehicleList: "vehicles/getVehiclesList",
      // iot
      // isLoadingIotDevices: "iotDevices/getIsLoadingIotDevices",
      iotDeviceMakesList: "iotDeviceMakes/iotDeviceMakesList",
      streamsList: "flespiStream/getStreamsList",
      pluginsList: "flespiPlugin/getPluginsList",
      calcsList: "flespiCalculator/getCalcsList",
      //
      routeOptions: "routeListing/getList",
      //
      countriesOptions: "getCountries",
      statesOptions: "getStates",
      citiesOptions: "getCities",
      //
      ticketCategoryLists: "supportTickets/ticketCategoryLists",
    }),
    isClearable() {
      // Check if the user nature is not 'fleet' and the current route does not contain 'vehicle-telematics'
      return (
        this.authUser.user_nature !== "fleet" &&
        !this.$route.name.includes("vehicle-telematics")
      );
    },
    tenantOEM() {
      return this.tenantsList.filter((r) => r.product == "oem");
    },
    tenantFLEET() {
      return this.tenantsList.filter((r) => r.product == "fleet");
    },
    SelectedFleetTeant() {
      // .filter((r) => options.oem_b2b_ids.includes(r.id))'
      let pIds = this.selectedFleet.parent_tenant_ids;
      const arr = this.tenantsList.filter((r) => pIds.includes(r.id));
      return arr;
    },
    tenantName() {
      if (this.authUser.user_nature == "fleet") {
        return this.$t("select_oem");
      } else {
        return this.$t("select_tenant");
      }
    },
  },
  watch: {
    vehiclesCheckbox: {
      handler() {
        if (
          this.vehiclesCheckbox.length === 0 ||
          this.vehicleList.length !== this.vehiclesCheckbox.length
        ) {
          this.selectAllVehicles = false;
        } else if (this.vehicleList.length === this.vehiclesCheckbox.length) {
          this.selectAllVehicles = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    // Pre Select Product Dropdown
    if (this.authUser.isAdmin) {
      this.options.product = this.$route.query.product || this.selectedProduct;
    } else if (this.authUser.user_nature == "fleet") {
      this.options.product = "fleet";
    } else {
      this.options.product = "oem";
    }
    // if (this.authUser.isAdmin || this.authUser.user_nature == "fleet") {
    if (this.authUser.isAdmin) {
      await this.$store.dispatch("tenantsManagement/options").then(() => {
        // For Fleet DropDown
        if (this.authUser.isAdmin && this.options.product == "fleet") {
          const qry = this.$route.query;
          this.selectedFleet = qry.fleetId
            ? this.tenantsList.find((r) => r.id == qry.fleetId)
            : this.tenantFLEET[0];
          this.options.fleetId = this.selectedFleet.id;
          this.options.is_independent_fleet =
            this.selectedFleet.is_independent_fleet;
          this.options.oem_b2b_ids = this.selectedFleet.oem_b2b_ids;
        }
        // For Tenant and OEM Dropdown Super
        if (this.options.product == "oem") {
          const qryTid = parseInt(this.$route.query?.tenant_id) || null;
          if (this.preSelectFilter) {
            this.options.tenant_id = qryTid || this.tenantOEM[0]?.id;
          } else {
            this.options.tenant_id = qryTid || null;
          }
        }
        // // For Tenant and OEM Dropdown fleet
        else if (this.options.product == "fleet") {
          const qryTid = parseInt(this.$route.query?.tenant_id) || null;
          const isIndep = this.$route.query?.is_independent_fleet;
          this.options.is_independent_fleet = isIndep ? true : false;
          if (this.authUser.isAdmin) {
            this.options.tenant_id = qryTid || this.SelectedFleetTeant[0]?.id;
          } else if (this.authUser.user_nature == "fleet") {
            this.options.tenant_id = qryTid || this.authUser.tenant_id;
            // if (router.currentRoute.name.includes("route-listing")) {
            //   // this.options.tenant_id = qryTid || this.tenantsList[0]?.id;
            // } else {
            //   this.options.tenant_id = qryTid || this.tenantsList[0]?.id;
            //   // this.options.tenant_id = qryTid || this.authUser.tenant_id;
            // }
          }
        }
        if (this.options.tenant_id || this.options.fleetId) {
          this.callTenantDepandent();
        }
      });
    } else {
      // add for OEM or Fleet
      this.options.tenant_id =
        this.authUser.parent_tenant_id || this.authUser.tenant_id;
      this.callTenantDepandent();
    }

    // pre select from Query params
    // this.options.search = 55;
    if (this.filterNames.includes("search")) {
      this.options.search = this.$route?.query?.search || null;
    }
    // makes
    if (this.filterNames.includes("make_type")) {
      this.options.make_type = this.$route?.query?.make_type || null;
    }
    // models
    if (this.filterNames.includes("make")) {
      this.options.md_make_id =
        parseInt(this.$route?.query?.md_make_id) || null;
      this.getModel(this.options.md_make_id);
    }
    if (this.filterNames.includes("model")) {
      // this.options.md_model_id = parseInt(this.$route?.query?.model_id) || null;
      this.options.md_model_id =
        parseInt(this.$route?.query?.md_model_id) || null;
    }
    this.isEnabled();
    if (this.filterNames.includes("vehicle_type")) {
      this.options.vehicle_type_id =
        parseInt(this.$route?.query?.vehicle_type_id) || null;
    }
    if (this.filterNames.includes("motor_type")) {
      this.options.motor_type_id =
        parseInt(this.$route?.query?.motor_type_id) || null;
    }
    if (this.filterNames.includes("battery_slot_type")) {
      this.options.battery_slot_type =
        this.$route?.query?.battery_slot_type || null;
    }
    if (this.filterNames.includes("category")) {
      this.options.category = this.$route?.query?.category || null;
    }
    //
    if (this.filterNames.includes("customer")) {
      this.options.user_type = this.$route?.query?.user_type || null;
      if (this.options.user_type == "business") {
        this.getOemB2B(this.options.tenant_id);
      }
      this.options.oem_b2b_id =
        parseInt(this.$route?.query?.oem_b2b_id) || null;
    }

    if (this.filterNames.includes("product_tier")) {
      this.options.product_id =
        parseInt(this.$route?.query?.product_id) || null;
      if (this.options.product_id) {
        this.options.product_tier_id =
          parseInt(this.$route?.query?.product_tier_id) || null;
      }
    }
    if (this.filterNames.includes("status")) {
      this.options.status = this.$route?.query?.status || null;
    }
    if (this.filterNames.includes("calendar_type")) {
      this.options.calendar_type = this.$route?.query?.calendar_type || "week";
    }
    if (this.filterNames.includes("iot_device_make")) {
      this.options.iot_device_make_id =
        parseInt(this.$route?.query?.iot_device_make_id) || null;
    }
    if (this.filterNames.includes("streams")) {
      if (this.$route?.query?.flespi_stream_id) {
        const id = this.$route.query.flespi_stream_id;
        this.options.flespi_stream_id = typeof id === "object" ? id : [id];
      }
    }
    if (this.filterNames.includes("plugins")) {
      if (this.$route?.query?.flespi_plugin_id) {
        const id = this.$route.query.flespi_plugin_id;
        this.options.flespi_plugin_id = typeof id === "object" ? id : [id];
      }
    }
    if (this.filterNames.includes("calculators")) {
      if (this.$route?.query?.flespi_calc_id) {
        const id = this.$route.query.flespi_calc_id;
        this.options.flespi_calc_id = typeof id === "object" ? id : [id];
      }
    }
    if (this.filterNames.includes("route")) {
      this.options.route_id = parseInt(this.$route.query.route_id) || null;
    }
    if (this.filterNames.includes("vehicle")) {
      if (this.$route?.query?.vehicle_ids) {
        const id = this.$route.query.vehicle_ids;
        this.options.vehicle_ids =
          typeof id === "object" ? id.map((r) => parseInt(r)) : [parseInt(id)];
        this.vehiclesCheckbox =
          typeof id === "object" ? id.map((r) => parseInt(r)) : [parseInt(id)];
        const imei = this.$route.query.imeis;
        this.options.imeis = typeof imei === "object" ? imei : [imei];
      }
    }
    if (this.filterNames.includes("single_vehicle")) {
      const id = this.$route.query.vehicle_id;
      this.options.vehicle_id = id ? parseInt(id) : null;
    }

    if (this.filterNames.includes("country")) {
      this.options.country_id = parseInt(this.$route.query.country_id) || null;
      if (this.options.country_id) {
        this.$store.dispatch("setStates", {
          country_id: this.options.country_id,
        });
      }
    }
    if (this.filterNames.includes("state")) {
      this.options.state_id = parseInt(this.$route.query.state_id) || null;
      if (this.options.state_id) {
        this.$store.dispatch("setCities", {
          state_id: this.options.state_id,
        });
      }
    }
    if (this.filterNames.includes("city")) {
      this.options.city_id = parseInt(this.$route.query.city_id) || null;
    }
    if (
      this.filterNames.includes("is-assigned_only") ||
      this.filterNames.includes("is-assigned")
    ) {
      this.options.is_assigned = this.$route.query.is_assigned || null;
    }
    // pre select from Query params

    this.filterData();
    if (this.isDateShow) {
      this.callAfterRendered = true;
    }
  },
  async mounted() {
    if (this.filterNames.includes("product_tier")) {
      this.$store.dispatch("products/product_with_tiers");
    }
    if (this.filterNames.includes("iot_device_make")) {
      this.$store.dispatch("iotDeviceMakes/list");
    }
    if (this.filterNames.includes("streams")) {
      this.$store.dispatch("flespiStream/list");
    }
    if (this.filterNames.includes("plugins")) {
      this.$store.dispatch("flespiPlugin/list");
    }
    if (this.filterNames.includes("calculators")) {
      this.$store.dispatch("flespiCalculator/list");
    }
    if (this.filterNames.includes("route")) {
      const params = { is_active: 1 };
      this.$store.dispatch("routeListing/list", params);
    }
    if (this.filterNames.includes("country")) {
      this.$store.dispatch("setCountries");
    }
  },
  methods: {
    async onProductChange(v) {
      if (this.authUser.isAdmin) {
        this.clearFilter();
        this.selectedFleet = {};
        delete this.options.fleetId;
        delete this.options.is_independent_fleet;
        delete this.options.oem_b2b_ids;
        delete this.options.tenant_id;
        if (this.preSelectFilter) {
          if (v == "oem") {
            this.options.tenant_id = this.tenantOEM[0]?.id || null;
          } else if (v == "fleet") {
            // selected fleet dropdown
            this.selectedFleet = this.tenantFLEET[0] || null;
            this.options.fleetId = this.selectedFleet.id || null;
            this.options.is_independent_fleet =
              this.selectedFleet.is_independent_fleet || null;
            this.options.oem_b2b_ids = this.selectedFleet.oem_b2b_ids || null;

            // oem dropdown
            this.options.tenant_id = this.SelectedFleetTeant[0]?.id || null;
          }
          //
          this.callTenantDepandent();
        } else {
          if (v == "fleet") {
            // selected fleet dropdown
            this.selectedFleet = this.tenantFLEET[0] || null;
            this.options.fleetId = this.selectedFleet.id || null;
            this.options.is_independent_fleet =
              this.selectedFleet.is_independent_fleet || null;
            this.options.oem_b2b_ids = this.selectedFleet.oem_b2b_ids || null;

            // oem dropdown
            this.options.tenant_id = this.SelectedFleetTeant[0]?.id || null;
          }
          //
          this.callTenantDepandent();
        }
        this.options = { ...this.options, ...this.options };
      }
      // delete this.options.tenant_id;
      this.emitData();
    },
    onFleetChange(v) {
      this.clearFilter();

      if (v) {
        this.options.fleetId = v.id;
        this.options.is_independent_fleet = v.is_independent_fleet;
        this.options.oem_b2b_ids = v.oem_b2b_ids;
        this.options.tenant_id = v?.parent_tenant_ids[0] || null;
        this.callTenantDepandent();
        // this.options.tenant_id = null;
      } else {
        this.options.tenant_id = null;
        delete this.options.fleetId;
        delete this.options.is_independent_fleet;
        delete this.options.oem_b2b_ids;
      }
      this.isEnabled();
      this.emitData();
    },
    onTenantChange() {
      this.userType = null;
      this.callTenantDepandent();
      this.clearFilter();
      this.isEnabled();
      this.emitData();
    },

    onDateChange(v) {
      this.options.date = v;
      if (this.callAfterRendered) {
        this.emitData();
      }
    },
    onDateKeyChange(v) {
      this.dataTypeValue = true;
      this.options.dateType = v;
    },
    emitData() {
      if (!this.authUser.isAdmin && this.authUser.user_nature != "fleet") {
        this.options.tenant_id = this.authUser.tenant_id;
      }
      this.$emit("onChange", this.options);
    },

    filterData() {
      if (
        this.isDateShow &&
        this.dateSelected &&
        !this.options.date &&
        !this.options.dateType
      ) {
        const cd = this.currentDate();
        this.options.date = this.$route.query.dateRange
          ? this.$route.query.dateRange
          : [cd, cd];
        this.options.dateType = this.$route.query.dateType
          ? this.$route.query.dateType
          : "today";
      } else if (
        !this.dataTypeValue &&
        this.isDateShow &&
        !this.options.date &&
        !this.options.dateType &&
        this.$route.query.dateRange
      ) {
        this.options.date = this.$route.query.dateRange;
        this.options.dateType = this.$route.query.dateType;
      } else if (this.options.dateType == null && this.dataTypeValue) {
        this.options.date = null;
        this.options.dateType = null;
      }
      const cleanedOptions = { ...this.options };
      Object.keys(cleanedOptions).forEach((key) => {
        if (cleanedOptions[key] === "") {
          delete cleanedOptions[key];
        }
      });
      this.dataTypeValue = true;
      this.$emit("onChange", cleanedOptions);
    },
    currentDate() {
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;

      day = day < 10 ? "0" + day : day;
      month = month < 10 ? `0${month}` : month;
      let year = date.getFullYear();
      // This arrangement can be altered based on how we want the date's format to appear.
      let cDate = `${year}-${month}-${day}`;
      return cDate;
    },

    //

    onSearch: debounce(function (v) {
      if (v != null) {
        v = v.trim();
        if (v == "") {
          this.options.search = null;
        }
        this.emitData();
      }
    }, 3000),

    onUserTypeChange(v) {
      this.userType = v;
      this.options.assignment_type = v;
      this.options.oem_b2b_id = null;
      if (v == "business") {
        this.getOemB2B(this.options.tenant_id);
      }

      this.emitData();
    },
    onBusinessChange() {
      this.emitData();
    },
    onMakeChange(v) {
      this.options = { ...this.options, md_model_id: null, md_make_id: v };
      this.$store.commit("mdModels/SET_MODEL", []);
      if (v) {
        this.getModel(v);
      }
      this.emitData();
    },
    onModelChange() {
      this.emitData();
    },
    onProductTierChange() {
      this.options.product_tier_id = null;
      this.emitData();
    },
    filterProductTier(val) {
      if (val) {
        return this.productWithTiers.find((r) => r.id == val)?.tiers;
      } else {
        return [];
      }
    },
    onStatusChange() {
      this.emitData();
    },
    onIsAssigned() {
      this.options.tenant_id = null;
      this.emitData();
    },
    onToggleChange() {
      if (this.selectAllVehicles) {
        this.vehiclesCheckbox = this.vehicleList.map((vehicle) => vehicle.id);
      } else {
        this.vehiclesCheckbox = [];
      }
      this.options.imeis = this.vehicleList
        .filter((r) => this.vehiclesCheckbox?.includes(r.id))
        .map((r) => r.iot_device_imei);
      this.options.vehicle_ids = this.vehiclesCheckbox;
      this.emitData();
    },
    onVehicleChangeCheckBox() {
      this.options.imeis = this.vehicleList
        .filter((r) => this.vehiclesCheckbox?.includes(r.id))
        .map((r) => r.iot_device_imei);
      this.options.vehicle_ids = this.vehiclesCheckbox;

      this.emitData();
    },
    onVehicleChange() {
      this.options.imeis = this.vehicleList
        .filter((r) => this.options?.vehicle_ids?.includes(r.id))
        .map((r) => r.iot_device_imei);

      this.emitData();
    },
    //
    onCountryChange(val) {
      this.options.country_id = val;
      this.options = { ...this.options, ...this.options };
      if (this.filterNames.includes("state")) {
        this.options.state_id = null;
        this.options.city_id = null;
        if (val) {
          this.$store.dispatch("setStates", { country_id: val });
        }
      }
      this.emitData();
    },
    onStateChange(val) {
      if (this.filterNames.includes("city")) {
        this.options.city_id = null;
        if (val) {
          this.$store.dispatch("setCities", { state_id: val });
        }
      }
      this.emitData();
    },
    //
    getOemB2B(id) {
      this.$store.dispatch("oemBusiness/list", {
        tenant_id: id,
      });
    },
    getModel(id) {
      if (this.filterNames.includes("model")) {
        if (this.options.md_make_id) {
          this.$store.dispatch("mdModels/list", {
            md_make_id: id ?? -1,
            tenant_id: this.options.tenant_id,
          });
        }
      }
      this.enableModel = true;
    },
    async callTenantDepandent() {
      const tID =
        this.options.product == "fleet" &&
        this.options.is_independent_fleet == true
          ? this.options.fleetId || this.options.tenant_id
          : this.options.tenant_id;

      if (tID) {
        let params = { tenant_id: tID };
        if (this.filterNames.includes("make")) {
          await this.$store.dispatch("mdMakes/list", {
            make_type: "tenant",
            ...params,
          });
        }
        if (this.filterNames.includes("vehicle")) {
          const params = {
            tenant_id: tID,
            compare_route: true,
          };
          await this.$store.dispatch("vehicles/list", params);
        }

        if (this.filterNames.includes("single_vehicle")) {
          const params = {
            tenant_id: tID,
            performance_comparison: true,
          };
          await this.$store.dispatch("vehicles/list", params);
        }
      }
    },
    clearFilter() {
      // this.options.search = undefined;
      this.options.make_type = undefined;
      this.options.user_type = undefined;
      this.options.oem_b2b_id = undefined;
      this.options.md_make_id = undefined;
      this.options.md_model_id = undefined;
      this.options.status = undefined;
      this.options.iot_device_make_id = undefined;
      this.options.vehicle_ids = [];
      this.options.vehicle_id = undefined;
      this.options.imeis = [];
      this.vehiclesCheckbox = [];
      this.selectAllVehicles = false;
      this.userType = null;
    },
    isEnabled() {
      if (this.options.tenant_id || this.options.fleetId) {
        this.enable = true;
        return true;
      } else {
        this.enable = false;
        return false;
      }
    },
    openFilterModal() {
      // Ensure that the reference is correctly pointing to the SlideInModal instance
      this.$refs.filterModal.showModal(); // Open the filter modal
    },
  },
};
</script>
<style lang="sass" scoped>
.top-filters
  display: flex
  flex-wrap: wrap
  gap: 1rem

:deep .v-card
  width: 14rem
  padding-left: 0rem
  padding-right: 0rem
  padding-top: 0rem
  height: 3.2rem

:deep .v-autocomplete
  .v-select__selections
    .selected
      width: 75px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

@media only screen and (max-width: 640px)
  :deep .v-card
    width: 100%
  :deep .DateDropDown
    width: 100% !important
// .v-select
//   :deep .v-select__slot
//     .v-select__selections
//       .v-select__selection
//         text-transform: capitalize !important

// .v-select-list
//   :deep .v-list-item
//     .v-list-item__content
//       .v-list-item__title
//         text-transform: capitalize !important

.searchField :deep .v-input .v-input__control .v-input__slot .v-text-field__slot input
  font-size: 0.8125rem !important

.searchField :deep .v-input .v-input__control .v-input__slot .v-input__append-inner
  height: 38px

.filtersModal
  align-items: flex-start
  :deep .modal-wrapper
    margin-top: 136px
</style>
